<template lang="pug">
    b-form.d-flex.flex-column.add-promotion(autocomplete='off')
        .add-promotion__input
            my-input(
                v-model.trim="form.title"
                :label="$t('promotions.form.name')"
                :errorFeedback="errors.title"
                :icon="'icon-lightning'"
                :disabled='isDisabledItem && create != "comebacker" || item.subscriptionsCount && item.subscriptionsCount != 0 ? true : false'
                @blur='isTitleValid()'
            )

            span.b4.text-primary.d-block.mt-10.fontello.cursor-pointer.add-promotion__gen(
                v-if="create == PLAN_TABS.promoCode && (item.subscriptionsCount && item.subscriptionsCount != 0 ? false : true)"
                @click="form.title = genPromo(5), isTitleValid()"
            ) {{ $t('h1.generate') }}

        dropdown-label.add-promotion__input(
            v-if="create == PLAN_TABS.tariff"
            :id="'period'"
            @onChange="getPeriod"
            @dropdownHide="validDropdown"
            :list="dropdownPeriods"
            :selectedId="form.period"
            :disabled='isDisabledItem || !canBeDeleted'
            :errorFeedback="errors.period"
            :icon="'icon-calendar'"
            :label="$t('forms.placeholder.paymentPeriod')"
        )

        .d-flex.position-relative.add-promotion__input.date-picker-control.date-picker-control--promotion(v-if="create != PLAN_TABS.tariff && create != PLAN_TABS.comebacker" :class="{'is-float': form.date.start, 'blocked' : isDisabledItem || !canBeDeleted || item.subscriptionsCount && item.subscriptionsCount != 0 ? true : false}")
            v-date-picker.w-100.date-picker(v-model="form.date" :key="creationDateKey" :masks="masks" is-range :locale="currentLanguage" :popover="{ visibility: 'click' }" :min-date="new Date()")
                template(v-slot="{ inputValue, inputEvents, togglePopover }")
                    span.icon-calendar(@click="togglePopover()")
                    .d-flex.date-picker__input(@click="togglePopover()")
                        input.b3.mr-1.date-picker__input-calendar.date-picker__input-calendar--first(:class="{'visually-hidden': !form.date.start && !form.date.end}" id="input-calendar" :value="inputValue.start" v-on="inputEvents.start" readonly)
                        span.b3.mr-1(v-if="form.date.start && form.date.end") -
                        span.b3.date-picker__input-calendar(v-if="form.date.start && form.date.end") {{ inputValue.end }}
                        input.visually-hidden(:value="inputValue.end" v-on="inputEvents.end")
            label.m-0.b3.date-picker__label(:class="{'b4': form.date.start && form.date.end}" for="input-calendar") {{ create != PLAN_TABS.tariff ? $t('promotions.datePicker.date') : $t('plan.period') }}
            span.b4.date-picker__date-counter(v-if="form.date.start && form.date.end") {{ dateCounter }} {{ declOfDays(dateCounter) }}
            icon-close.date-picker__reset(v-if="form.date.start" @click="form.date.start = null, form.date.end = null, creationDateKey++")

        template(v-if="create != PLAN_TABS.tariff && form.date.start && create != PLAN_TABS.comebacker" )
            b-form-checkbox.b3(id="display-payment" v-model="form.isShowPeriod" name="display-payment" :class="{'blocked' : isDisabledItem}" :value="true" :unchecked-value="false") {{ $t('promotions.form.isShowPeriod') }}

        .d-flex.add-promotion__input.is-discount(v-if="create != PLAN_TABS.tariff")
            .d-flex.flex-column.add-promotion__input--w-50(:class="{'w-100': (create == PLAN_TABS.stock || create == PLAN_TABS.comebacker)}")
                .position-relative
                    my-input(
                        v-model="form.discount"
                        :value="form.discount"
                        :label="$t('promotions.form.discount')"
                        :errorFeedback="errors.discount"
                        :icon="'icon-discount'"
                        :maxlength="'2'"
                        :mask="'##'"
                        :disabled='!canBeDeleted || (isDisabledItem && create != PLAN_TABS.comebacker) || item.subscriptionsCount && item.subscriptionsCount != 0 ? true : false || item.countComebacks && item.countComebacks > 0 ? true: false || (create == PLAN_TABS.comebacker && !item.canBeDeleted && item.hasOwnProperty("canBeDeleted"))'
                        :type="'number'"
                        @blur='isDiscountValid()'
                    )
                    span.d-flex.justify-content-center.align-items-center.b2.add-promotion__tooltip-discount(v-if="!isMobile" id="tooltip-discount" :class='{"blocked" : !canBeDeleted || (isDisabledItem && create != PLAN_TABS.comebacker)}') ?
                    span.d-flex.justify-content-center.align-items-center.b2.add-promotion__tooltip-discount(v-if="isMobile" v-b-modal.tooltipDiscount :class='{"blocked" : !canBeDeleted || (isDisabledItem && create != PLAN_TABS.comebacker)}') ?

            .d-flex.flex-column.add-promotion__input--w-50.add-promotion__usage(v-if="create == PLAN_TABS.promoCode")
                my-input(
                    v-model="form.usage.total"
                    :mask="'######'"
                    :label="$t('promotions.form.usage')"
                    :errorFeedback="errors.capacity"
                    :icon="'icon-usage'"
                    :disabled='isDisabledItem || item.subscriptionsCount && item.subscriptionsCount != 0 ? true : false'
                    @blur='isCapacityValid()'
                )

        .add-promotion__input(v-if="create == PLAN_TABS.comebacker")
            span.d-block.b4.text-grey.mb-10(:class="{'blocked' : !canBeDeleted}") {{ $t('promotions.datePicker.date') }}
            .d-flex.justify-content-between.flex-wrap
                my-input.add-promotion__input--w-50.is-validity(
                    v-model="form.hours"
                    :label="$t('promotions.datePicker.hours')"
                    :errorFeedback="errors.hours"
                    :mask="'##'"
                    :disabled='!canBeDeleted || item.countComebacks && item.countComebacks > 0 ? true: false || (create == PLAN_TABS.comebacker && !item.canBeDeleted && item.hasOwnProperty("canBeDeleted"))'
                    @blur='isHoursValid()'
                )
                my-input.add-promotion__input--w-50.is-validity(
                    v-model="form.minutes"
                    :label="$t('promotions.datePicker.minutes')"
                    :errorFeedback="errors.minutes"
                    :mask="'##'"
                    :disabled='!canBeDeleted || item.countComebacks && item.countComebacks > 0 ? true: false || (create == PLAN_TABS.comebacker && !item.canBeDeleted && item.hasOwnProperty("canBeDeleted"))'
                    @blur='isMinutesValid()'
                )

        .add-promotion__textarea-text(v-if="create == PLAN_TABS.comebacker")
            my-text-area(
                v-model="form.textSubscription"
                :label="$t('promotions.comebacker.textForSubscribers')"
                :errorFeedback="errors.textSubscription"
                :maxlength="251"
                @blur='isTextSubscriptionValid()'
                @input="isTextSubscriptionValid()"
            )

        my-input.add-promotion__input(
            v-if="create == PLAN_TABS.tariff"
            v-model="form.price"
            :label="$t('promotions.form.price')"
            :errorFeedback="errors.price"
            :icon="'icon-money'"
            :disabled='isDisabledPCode || isDisabledPrice || isDisabledItem || !canBeDeleted'
            :mask="'######'"
            @blur='isPriceValid()'
        )

        dropdown-label.add-promotion__input(
            v-if="create == PLAN_TABS.tariff"
            :id="'currency'"
            @onChange="getCurrency"
            @dropdownHide="validDropdown"
            :list="dropdownCurrency"
            :selectedId="form.currency"
            :disabled='isDisabledPCode || isDisabledPrice || isDisabledItem || !canBeDeleted'
            :errorFeedback="errors.currency"
            :icon="'icon-buy-crypto'"
            :label="$t('forms.placeholder.currency')"
        )

        dropdown-label.add-promotion__input(
            v-if="create == PLAN_TABS.tariff"
            @onChange="getFreePeriod"
            :list="dropdownFreePeriods"
            :selectedId="form.freePeriod"
            :icon="'icon-gift'"
            :disabled='isDisabledItem'
            :label="$t('forms.placeholder.free')"
        )

        div(v-if="resources.length > 0")
            .d-flex.flex-column.mb-10(v-for="(channel, i) in resources" :key="`channel-${i}`" :class='{"blocked" : isDisabledItem}')
                b-form-checkbox.add-promotion__channel(ref="addPromotionChannel" v-model="form.selectedChannels" :id="`channel-${i}`" :value="channel.id" :class="{'active-plan': form.selectedChannels.includes(resources[i].id)}")
                    .d-flex.justify-content-center.align-items-center.add-promotion__avatars(:class="{'brand-gradient': !channel.image || !channel.image.contentUrl}")
                        img(v-if="channel.image && channel.image.contentUrl" :src="channel.image.contentUrl")
                        span(v-else) {{ `${channel.title}`.slice(0,1) }}
                    .d-flex.flex-column.align-items-start
                        span.b3.ml-10 {{ channel.title }}
                        b-badge.b3.ml-10.add-promotion__bot-disconnected(v-if="channel.isBotRemoved() || channel.hasBotIssue()" variant="danger" pill v-html="$t('bot.disconnected')")
            .text-danger.b4(v-if="errorResource" v-html="$t('errors.required.resource')")

        p.b3.disclaimer.error.add-promotion__error(v-else-if='create == PLAN_TABS.tariff')
            span.b3 {{ $t('promotions.tariff.withoutResourcesDesc2') }}
            span.btn.btn-subtle.add-promotion__disclaimer-btn(@click="goToResources") {{ $t('button.goTo') }}

        div(v-if="plans.length > 0")
            span.d-flex.b1.mb-10 {{ $t('promotions.plans') }}

            .d-flex.align-items-center.border-rounded.add-promotion__warning.add-promotion__warning-archive(v-if="create == PLAN_TABS.comebacker && item.status == 'ARCHIVE' && form.selectedPlans.length == 0")
                svg.flex-shrink-0.add-promotion__warning-icon(width='23' height='22' viewbox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg')
                    rect(x='1.5' y='1' width='20' height='20' rx='5' stroke='#FCB64D' stroke-width='1.5')
                    path(d='M12 16L12 10' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
                    path(d='M10 10L12 10' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
                    path(d='M12 7L12 6' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
                span.b3.add-promotion__warning-txt {{ $t('promotions.comebacker.selectTariffToComebackerActive')}}

            .d-flex.flex-column.mb-10(v-for="(plan, i) in plans" :key="`plan-${i}`" :class='{"blocked" : isDisabledItem && create != "comebacker"}')
                .add-promotion__plan(:class="{'active-plan': form.selectedPlans.includes(plans[i].id), 'is-dark': isDarkColor, 'error-plan': !isPlanValid(plan) && form.selectedPlans.includes(plans[i].id), 'is-blocked' : isComebackerPlans(plan.id) && create == PLAN_TABS.comebacker}")
                    b-form-checkbox(ref="addPromotionPlan" v-model="form.selectedPlans" :id="`plan-${i}`" :value="plan.id" @change="errorPricePlan")
                        .d-flex.justify-content-between.align-items-center.w-100.add-promotion__plan-top
                            .d-flex.align-items-center.mr-3.add-promotion__plan-header
                                span.b3(v-html="plan.title")
                                span(v-if="plan.status == 'ARCHIVE'" :id="!isMobile ? `tariff-archive-${plan.id}` : ''" @click.prevent="isMobile ? handleClickArchive(plan.id) : ''")
                                    svg.ml-10.add-promotion__plan-archive(width='27' height='26' viewbox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg')
                                        rect.add-promotion__plan-archive--rect(x='0.5' width='26' height='26' rx='4')
                                        path.add-promotion__plan-archive--path(d='M19.125 11.6641V18.2491C19.125 19.7491 18.75 20.4991 16.875 20.4991H10.125C8.25 20.4991 7.875 19.7491 7.875 18.2491V11.6641' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
                                        path.add-promotion__plan-archive--path(d='M18.75 5.5C20.25 5.5 21 6.25 21 7.75V9.25C21 10.75 20.25 11.5 18.75 11.5H8.25C6.75 11.5 6 10.75 6 9.25V7.75C6 6.25 6.75 5.5 8.25 5.5H18.75Z' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
                                        path.add-promotion__plan-archive--path(d='M12.1348 14.5H14.8648' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')

                                b-tooltip(v-if="!isMobile" :target="`tariff-archive-${plan.id}`" no-fade placement="bottom" custom-class="archive-tooltip")
                                    span.b3 {{ $t('promotions.toArchive') }}

                                b-modal(v-if="isMobile" :id="`tariff-archive-${plan.id}`" content-class="modal-tooltip__modal" header-class="p-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
                                    template(#modal-header="{ close }")
                                        h3.h3 {{ $t('promotions.archive')}}
                                        icon-close(@click="close()")
                                    p.b3.add-promotion__modal-txt {{ $t('promotions.toArchive') }}.
                                    span.btn.btn-primary(@click="$bvModal.hide(`tariff-archive-${plan.id}`)") {{ $t('promotions.btnFine') }}

                            .d-flex.align-items-end
                                span.b3.mr-10.add-promotion__price--old.text-nowrap(v-if="form.selectedPlans.includes(plans[i].id)" v-html="`${plan.price.priceWithCurrency()}`")
                                span.b1.add-promotion__price.text-nowrap.text-bold(v-if="form.selectedPlans.includes(plans[i].id)" v-html="`${planPriceWithDiscount(plan)}&nbsp;${plan.price.currencyTitle()}`")
                                span.b1.add-promotion__price.text-nowrap.text-bold(v-if="!form.selectedPlans.includes(plans[i].id)" v-html="`${plan.price.priceWithCurrency()}`")

                        .d-flex.justify-content-between.align-items-center.w-100.add-promotion__plan-bottom(v-if="create == PLAN_TABS.comebacker")
                            span.text-bold.text-grey.add-promotion__subscription(v-if="plan.countSubscriptions == 0") {{ $t('promotions.noSubscriptions') }}
                            span.text-bold.text-grey.add-promotion__subscription(v-if="plan.countSubscriptions > 0") {{ plan.countSubscriptions }} {{ declOfSubscriptions(plan.countSubscriptions) }}
                            span.text-bold.text-grey.border-rounded.add-promotion__comeback(v-if="(form.selectedPlans.includes(plans[i].id) && getCountComebacksByComebacker(plan.countComebacks, item.id)) === 0 || (modalId!=='comebacker-form' && getCountComebacksByComebacker(plan.countComebacks, item.id) === 0 && form.selectedPlans.includes(plans[i].id))") {{ $t('promotions.noComebacks') }}
                            span.text-bold.text-grey.border-rounded.add-promotion__comeback.is-count(v-if="getCountComebacksByComebacker(plan.countComebacks, item.id) > 0") {{ getCountComebacksByComebacker(plan.countComebacks, item.id) }} {{ declOfComebacks(plan.countComebacks[0].countComebacks) }}

                    .d-flex.align-items-center.border-rounded.add-promotion__warning(v-if="create == PLAN_TABS.comebacker && isComebackerPlans(plan.id)")
                        svg.flex-shrink-0.add-promotion__warning-icon(width='24' height='24' viewbox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
                            rect(x='2' y='2' width='20' height='20' rx='5' stroke='#FCB64D' stroke-width='1.5')
                            path(d='M12.5 17L12.5 11' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
                            path(d='M10.5 11L12.5 11' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
                            path(d='M12.5 8L12.5 7' stroke='#FCB64D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
                        span.b4.text-grey.d-block {{ $t('promotions.comebacker.tariffInAnotherComebacker')}}

                .text-danger.b4.mt-1(v-if="!isPlanValid(plan) && form.selectedPlans.includes(plan.id)" v-html='planError(plan)')
            .text-danger.b4(v-if="errorRate" v-html="$t('errors.required.tariff')")

        div.b3.disclaimer.error.add-promotion__error(v-else-if='create == PLAN_TABS.stock')
            span.b3 {{ $t('promotions.stock.noPlansDesc') }}
            span.btn.btn-subtle.add-promotion__disclaimer-btn(@click="goToPlans") {{ $t('button.goTo') }}

        div.b3.disclaimer.error.add-promotion__error(v-else-if='create == PLAN_TABS.promoCode')
            span.b3 {{ $t('promotions.promoCodes.noPlansDesc') }}
            span.btn.btn-subtle.add-promotion__disclaimer-btn(@click="goToPlans") {{ $t('button.goTo') }}

        div.b3.disclaimer.error.add-promotion__error(v-else-if='create == PLAN_TABS.comebacker')
            span.b3 {{ $t('promotions.comebacker.noPlansDesc') }}
            span.btn.btn-subtle.add-promotion__disclaimer-btn(@click="goToPlans") {{ $t('button.goTo') }}

        .d-flex.flex-grow-1.justify-content-end.add-promotion__button
            span.btn.btn-primary.w-100.mt-auto(v-if='!isDisabledItem' @click="createPromotion" :class="{'disabled': isDisabled || isloading}")
                .loader(v-if="isloading")
                span(v-if='form.id && !isloading') {{ $t('forms.save') }}
                span(v-else-if="!isloading") {{ $t('promotions.form.btn') }}
            span.btn.btn-primary.w-100.mt-auto(v-else @click="unarchive" :class="{'disabled': isDisabled || isloading || !isEditable}")
                .loader(v-if="isloading")
                span(v-else-if='create == PLAN_TABS.tariff') {{ $t('project.unarchiveProject') }}
                span(v-else-if='create == PLAN_TABS.stock') {{ $t('project.unarchivePromotion') }}
                span(v-else-if='create == PLAN_TABS.promoCode') {{ $t('project.unarchivePromocode') }}
                span(v-else-if='create == "comebacker"') {{ $t('project.unarchiveComebacker') }}
            span.btn.ml-10.add-promotion__archive(v-if='form.id && create == "comebacker" && !isDisabledItem' @click="archiveComeback")
                svg.add-promotion__archive-icon(width='21' height='20' viewbox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg')
                    path(d='M16.75 8.51758V15.8342C16.75 17.5009 16.3333 18.3342 14.25 18.3342H6.75C4.66667 18.3342 4.25 17.5009 4.25 15.8342V8.51758' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
                    path(d='M16.3337 1.66602C18.0003 1.66602 18.8337 2.49935 18.8337 4.16602V5.83268C18.8337 7.49935 18.0003 8.33268 16.3337 8.33268H4.66699C3.00033 8.33268 2.16699 7.49935 2.16699 5.83268V4.16602C2.16699 2.49935 3.00033 1.66602 4.66699 1.66602H16.3337Z' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
                    path(d='M8.9834 11.666H12.0167' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')

        b-tooltip(target="tooltip-discount" placement="bottom" no-fade custom-class="discount-tooltip")
            p.b3.mb-10 {{ $t('promotions.form.tooltipDiscount') }}
            pw-btn-link(:text="$t('promotions.promotionsInfo.knowledgeBase')" :href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/tarify#limity-na-stoimost'" :blank='true')

        b-tooltip(v-if="create != PLAN_TABS.tariff" target="tooltip-discount" placement="bottom" no-fade triggers="hover")
            p.b3.mb-3 {{ $t('promotions.form.tooltipDiscount') }}
            pw-btn-link(:text="$t('promotions.promotionsInfo.plans[0]')" :href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/tarify#limity-na-stoimost'" :icon="'icon-document'" :blank='true')

        b-modal#tooltipDiscount(content-class="add-promotion__modal-discount" header-class="p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
            template(#modal-header="{ close }")
                h3.h3 {{ $t('promotions.attention') }}
                icon-close(@click="close()")
            p.b3.mb-4 {{ $t('promotions.form.tooltipDiscount') }}
            pw-btn-link.add-promotion__modal-txt(:text="$t('promotions.promotionsInfo.knowledgeBase')" :href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/tarify-i-akcii/tarify#limity-na-stoimost'" :blank='true' :icon="'icon-document'")
            span.btn.btn-primary(@click="$bvModal.hide('tooltipDiscount')") {{ $t('promotions.btnFine') }}
</template>

<script>
import DropdownLabel from '@/components/Dropdown/DropdownLabel';
import PwBtnLink from '@/components/Buttons/PwBtnLink';
import IconClose from '@/components/Common/IconClose';
import MyInput from '@/components/UI/MyInput';
import MyTextArea from '@/components/UI/MyTextArea';
import { Plan, PERIODS, PERIODSFREE } from '@/models/plan';
import { PLAN_TABS } from '@/models/project';
import { CURRENCY_EMOJI, Money } from '@/models/money';
import { STATUS_ACTIVE } from '@/models/model';
import { STORAGE } from '@/api/storage';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';

export default {
    name: 'AddPromotion',
    components: {
        DropdownLabel,
        PwBtnLink,
        IconClose,
        MyInput,
        MyTextArea,
    },
    props: {
        create: {
            default: '',
            type: String,
        },
        modalId: {
            default: '',
            type: String,
        },
        plans: {
            default: () => [],
            type: Array,
        },
        channels: {
            default: () => [],
            type: Array,
        },
        item: {
            default: () => ({}),
            type: Object,
        },
    },
    data: () => ({
        creationDateKey: 0,
        PLAN_TABS: PLAN_TABS,
        resources: [],
        dateCounter: null,
        currentLanguage: STORAGE.getLang(),
        isDisabled: true,
        isDisabledPrice: false,
        isDisabledPCode: false,
        isEditable: true,
        masks: {
            input: 'DD.MM.YYYY',
            weekdays: 'WW',
        },
        dropdownPeriods: [],
        dropdownCurrency: [],
        dropdownFreePeriods: [],
        form: {
            title: null,
            date: {
                start: null,
                end: null,
            },
            isShowPeriod: false,
            discount: null,
            hours: null,
            minutes: null,
            textSubscription: null,
            selectedPlans: [],
            usage: {
                total: null,
            },
            period: null,
            price: null,
            currency: null,
            freePeriod: null,
            selectedChannels: [],
        },
        errors: {
            title: '',
            discount: '',
            price: '',
            capacity: '',
            hours: '',
            minutes: '',
            textSubscription: '',
            period: '',
            currency: '',
        },
        isloading: false,
        errorResource: false,
        errorRate: false,
    }),
    created() {
        this.getDropdownPeriods();
        this.getDropdownCurrency();
        this.getDropdownFreePeriods();

        if (this.create == PLAN_TABS.stock) {
            this.setDefaultStock(this.item);
            this.dateCounter = this.getDateCounter;
            this.validationStock(this.form);
        } else if (this.create == PLAN_TABS.promoCode) {
            this.setDefaultPromoCode(this.item);
            this.dateCounter = this.getDateCounter;
            this.validationPromoCode(this.form);
        } else if (this.create == PLAN_TABS.tariff) {
            this.setDefaultData(this.item);
            this.validationTariff(this.form);
        } else if (this.create == PLAN_TABS.comebacker) {
            this.setDefaultComebackerData(this.item);
            this.validationComebacker(this.form);
        }
    },
    computed: {
        ...mapGetters({
            lastOpenedProjectId: 'project/lastOpenedProjectId',
            projectChannels: 'resources/channels',
            projectGroups: 'resources/groups',
            project: 'project/opened',
            promotions: 'project/promotions',
            promocodes: 'project/promocodes',
            canBeDeletedPlans: 'project/canBeDeletedPlans',
            comebackersList: 'comebackers/items',
            comebackerPlans: 'comebackers/items',
        }),
        getDateCounter() {
            if (this.create == PLAN_TABS.tariff) return null;

            const oneDay = 1000 * 60 * 60 * 24;
            const diffInTime = moment(this.form.date.end).diff(this.form.date.start);
            const diffInDays = Math.round(diffInTime / oneDay + 1);
            return diffInDays;
        },
        isDisabledItem() {
            return this.item && this.item.isArchived && this.item.isArchived();
        },
        canBeDeleted() {
            if (
                this.canBeDeletedPlans.filter(el => el.id == this.item.id)[0] &&
                this.canBeDeletedPlans.filter(el => el.id == this.item.id)[0].isCanBeDeleted === false
            )
                return false;

            return true;
        },
    },
    mounted() {
        if (this.modalId == `edit-tariff-${this.item.id}`) this.setResources();
    },
    methods: {
        ...mapActions({
            getPlansPlain: 'plans/plansPlain',
            getCanBeDeleted: 'project/canBeDeleted',
            addPlan: 'plans/add',
            editPlan: 'plans/edit',
            removePlan: 'plans/remove',
            putChannels: 'plans/putChannels',
            putGroups: 'plans/putGroups',

            addPromotion: 'project/addPromotion',
            editPromotion: 'project/editPromotion',

            addPromocode: 'project/addPromocode',
            editPromocode: 'project/editPromocode',

            addComebacker: 'comebackers/addComebacker',
            editComebacker: 'comebackers/editComebacker',
            archiveComebacker: 'comebackers/archive',

            getComebackersList: 'comebackers/comebackersList',

            aPlan: 'plans/activate',
            aPromotion: 'project/activatePromotion',
            aPromocode: 'project/activatePromocode',
        }),
        getCountComebacksByComebacker(countComebacks, comebackerId) {
            let result = countComebacks.find(value => value.comebackerId == comebackerId);
            if (result === undefined) {
                return 0;
            } else {
                return result.countComebacks;
            }
        },
        isComebackerPlans(id) {
            /*Собираем все ID планов во всех камбекерах*/
            let comebackerPlansId = this.comebackerPlans.map(x => x.plans.map(i => i.id)).flat();
            /*Ищем открытый камбекер в модалке*/
            let openComebacker = this.comebackersList.filter(x => x.id == this.form.id);
            /*Собираем ID планов в открытом камбекере модалки*/
            let openComebackerPlans = openComebacker.length > 0 ? openComebacker[0].plans.map(x => x.id) : null;

            /*Удаляем ID планов открытого камбекера из общего списка ID планов камбекеров*/
            if (openComebackerPlans) {
                openComebackerPlans.forEach(el => {
                    if (comebackerPlansId.includes(el)) comebackerPlansId = comebackerPlansId.filter(x => x != el);
                });
            }

            if (comebackerPlansId.includes(id)) {
                return true;
            } else {
                return false;
            }
        },
        goToResources() {
            this.$router.push({ name: 'project_resources' });
        },
        goToPlans() {
            this.$emit('goToPlans', this.$event);
            this.$bvModal.hide('promo-code-form');
            this.$bvModal.hide('stock-form');
            this.$bvModal.hide('comebacker-form');
        },
        getPeriod(data) {
            this.form.period = data;
        },
        getCurrency(data) {
            this.form.currency = data;
            if (this.form.price) this.isPriceValid();
        },
        getFreePeriod(data) {
            this.form.freePeriod = data;
        },
        unarchive() {
            this.isloading = true;

            if (this.create == PLAN_TABS.tariff) {
                return this.aPlan({ id: this.form.id })
                    .then(v => {
                        this.editTariff(true);
                        this.$notify('success', this.$t('success.planActive'));
                        this.isloading = false;
                    })
                    .catch(v => {
                        this.$notify('error', '😢 &nbsp;&nbsp;&nbsp;' + v);
                        this.isloading = false;
                    });
            }
            if (this.create == PLAN_TABS.stock) {
                return this.editPromotion({ id: this.form.id, status: { value: STATUS_ACTIVE } })
                    .then(v => {
                        this.item.setArchive(false);
                        this.$notify('success', this.$t('success.promotionActive'));
                        this.isloading = false;
                    })
                    .catch(v => {
                        this.$notify('error', '😢 &nbsp;&nbsp;&nbsp;' + v);
                        this.isloading = false;
                    });
            }
            if (this.create == PLAN_TABS.promoCode) {
                return this.editPromocode({ id: this.form.id, status: { value: STATUS_ACTIVE } })
                    .then(v => {
                        this.item.setArchive(false);
                        this.$notify('success', this.$t('success.promocodeActive'));
                        this.isloading = false;
                    })
                    .catch(v => {
                        this.$notify('error', '😢 &nbsp;&nbsp;&nbsp;' + v);
                        this.isloading = false;
                    });
            }
            if (this.create == PLAN_TABS.comebacker) {
                let data = this.prepareComebackerDate();
                data.id = this.form.id;

                return this.editComebacker(data)
                    .then(v => {
                        this.updatePlans();
                        this.$notify('success', this.$t('success.comebackerActive'));
                        this.isloading = false;
                    })
                    .catch(v => {
                        this.$notify('error', '😢 &nbsp;&nbsp;&nbsp;' + v);
                        this.isloading = false;
                    });
            }
        },
        async createPromotion() {
            this.isloading = true;
            if (this.create == PLAN_TABS.stock) {
                if (this.form.id) return this.editStock();

                this.addStock();
            } else if (this.create == PLAN_TABS.promoCode) {
                if (this.form.id) return this.editCode();

                this.addCode();
            } else if (this.create == PLAN_TABS.tariff) {
                if (this.form.id) return this.editTariff();

                this.addTariff();
            } else if (this.create == PLAN_TABS.comebacker) {
                if (this.form.id) return this.editComeback();

                this.addComeback();
            }
        },
        getDropdownPeriods() {
            this.dropdownPeriods = _.map(
                _.filter(PERIODS, pp => pp.active),
                p => ({ id: p.id, title: this.$t(`plan.periodsSelect.${p.title}`) })
            );
        },
        getDropdownCurrency() {
            this.dropdownCurrency = _.map(CURRENCY_EMOJI, (k, i) => {
                return { id: i, title: `${i} ${k}` };
            });
        },
        getDropdownFreePeriods() {
            this.dropdownFreePeriods = _.map(PERIODSFREE, p => ({
                id: p.id,
                title: this.$t(`plan.freePeriods.${p.title}`),
            }));
        },
        // Функция правильного склонения слов
        declOfNum(n, text_arr) {
            let num = Math.abs(n) % 100;
            let n1 = num % 10;

            if (num > 10 && num < 20) {
                return text_arr[2];
            }
            if (n1 > 1 && n1 < 5) {
                return text_arr[1];
            }
            if (n1 == 1) {
                return text_arr[0];
            }
            return text_arr[2];
        },
        declOfDays(num) {
            return this.declOfNum(num, [
                this.$t('promotions.declOfDays[0]'),
                this.$t('promotions.declOfDays[1]'),
                this.$t('promotions.declOfDays[2]'),
            ]);
        },
        declOfSubscriptions(num) {
            return this.declOfNum(num, [
                this.$t('promotions.declOfSubscriptions[0]'),
                this.$t('promotions.declOfSubscriptions[1]'),
                this.$t('promotions.declOfSubscriptions[2]'),
            ]);
        },
        declOfComebacks(num) {
            return this.declOfNum(num, [
                this.$t('promotions.declOfComebacks[0]'),
                this.$t('promotions.declOfComebacks[1]'),
                this.$t('promotions.declOfComebacks[2]'),
            ]);
        },
        errorPricePlan() {
            if (this.$refs.addPromotionPlan) {
                this.$refs.addPromotionPlan.forEach(item => {
                    if (item.$el.classList.contains('error-plan')) {
                        this.isDisabled = true;
                    }
                });
            }
        },
        isTitleValid() {
            let valid = true;
            let v = this.form.title;
            this.errors.title = '';

            if (this.create == PLAN_TABS.tariff) {
                if (_.find(this.project.plans, p => p.title == v && this.item.title != v)) {
                    this.errors.title = this.$t('errors.planAlreadyExist');
                    return false;
                }
            }

            if (this.create == PLAN_TABS.tariff) {
                if (v == null || v.length == 0) {
                    this.errors.title = this.$t('errors.required.title');
                    return false;
                }

                if (v.length < 3) {
                    valid = false;
                    this.errors.title = this.$t('errors.lengthStock');
                }

                if (v.length > 50) {
                    valid = false;
                    this.errors.title = this.$t('errors.lengthStock');
                }
            }

            if (this.create == PLAN_TABS.stock) {
                if (v == null || v.length == 0) {
                    this.errors.title = this.$t('errors.required.title');
                    return false;
                }

                if (v.length < 3) {
                    valid = false;
                    this.errors.title = this.$t('errors.lengthStock');
                }

                if (v.length > 50) {
                    valid = false;
                    this.errors.title = this.$t('errors.lengthStock');
                }
            }

            if (this.create == PLAN_TABS.stock) {
                const regex = /^[a-zа-яёË0-9\s.,?!:-–—-]+$/iu;

                if (v.indexOf('@') != -1) {
                    valid = false;
                    this.errors.title = this.$t('errors.alpha.dots');
                }

                if (v.indexOf('/') != -1) {
                    valid = false;
                    this.errors.title = this.$t('errors.alpha.dots');
                }

                if (!regex.test(v) && v.indexOf('@') != -1 && v.indexOf('/') != -1) {
                    valid = false;
                    this.errors.title = this.$t('errors.alpha.dots');
                }
            }

            if (this.create == PLAN_TABS.promoCode) {
                const regex = /^[a-z0-9]+$/iu;
                if (!regex.test(v)) {
                    valid = false;
                    this.errors.title = this.$t('errors.alpha.enNumbers');
                }

                if (v == null || v.length === 0) {
                    valid = false;
                    this.errors.title = this.$t('promotions.form.errorTitle');
                }

                if (v.length < 4 && v.length != 0) {
                    valid = false;
                    this.errors.title = this.$t('errors.lengthPromo');
                }

                if (v.length > 6) {
                    valid = false;
                    this.errors.title = this.$t('errors.lengthPromo');
                }

                if (_.find(this.promocodes, p => p.title == v && this.item.title != v)) {
                    this.errors.title = this.$t('errors.promoCodeNameAlreadyExist');
                    return false;
                }
            }

            if (this.create == PLAN_TABS.comebacker) {
                if (v == null || v.length == 0) {
                    this.errors.title = this.$t('errors.required.title');
                    return false;
                }
                if (v.length > 50 || v.length < 3 || _.toArray(v).length < 3) {
                    valid = false;
                    this.errors.title = this.$t('errors.lengthStock');
                }

                if (v && _.find(this.comebackersList, p => p.title == v && this.item.title != v)) {
                    this.errors.title = this.$t('errors.comebackerNameAlreadyExist');
                    return false;
                }
            }

            return valid;
        },

        isDiscountValid() {
            let valid = true;
            let v = this.form.discount;
            this.errors.discount = '';

            if (v == null || v.length == 0 || v == 0) {
                valid = false;
                this.errors.discount = this.$t('promotions.form.error');
            }

            if (v != null && v.length != 0 && (Number(v) < 10 || Number(v) > 90)) {
                valid = false;
                this.errors.discount = this.$t('promotions.form.errorDiscountRange');
            }

            return valid;
        },

        isHoursValid() {
            let formHours = this.form.hours;
            let formMinutes = this.form.minutes;
            let minutes = Number(formHours) * 60 + Number(formMinutes);
            this.errors.hours = '';

            if (formHours != null && minutes > 1440) {
                this.errors.hours = this.$t('promotions.form.errorHoursRange');
            }

            if (minutes < 30) {
                this.errors.minutes = this.$t('promotions.form.errorMinutesRange');
            } else {
                this.errors.minutes = '';
            }

            if (formMinutes) {
                this.isMinutesValid();
            }
        },

        isMinutesValid() {
            let formHours = this.form.hours;
            let formMinutes = this.form.minutes;
            let minutes = Number(formHours) * 60 + Number(formMinutes);
            this.errors.minutes = '';

            if (minutes == null || minutes.length == 0 || minutes == 0) {
                this.errors.minutes = this.$t('promotions.form.error');
            }

            if (this.form.minutes >= 60) {
                this.form.minutes = 59;
            }

            if (minutes < 30) {
                this.errors.minutes = this.$t('promotions.form.errorMinutesRange');
            }

            if (formHours) {
                this.errors.hours = '';

                if (formHours != null && minutes > 1440) {
                    this.errors.hours = this.$t('promotions.form.errorHoursRange');
                }
            }
        },

        isTextSubscriptionValid() {
            let valid = true;
            let v = this.form.textSubscription;
            this.errors.textSubscription = '';

            if (v == null || v == '' || v.length == 0) {
                valid = false;
                this.errors.textSubscription = this.$t('promotions.form.errorTextSubscriptionEmpty');
            }

            if (v != null && v != '' && (v.length < 10 || v.length > 250 || _.toArray(v).length < 10)) {
                valid = false;
                this.errors.textSubscription = this.$t('promotions.form.errorTextSubscriptionRange');
            }

            return valid;
        },

        isCapacityValid() {
            let valid = true;
            this.errors.capacity = '';

            if (this.form.usage.total % 1 != 0) {
                this.errors.capacity = this.$t('errors.numeric.capacity');
            }

            if (this.form.usage.total && this.form.usage.total < 1) {
                valid = false;
                this.errors.capacity = this.$t('errors.numeric.1');
            }

            return valid;
        },

        isPriceValid() {
            let valid = true;
            let v = this.form.price;
            let currency =
                _.isObject(this.form.currency) && this.form.currency.title
                    ? this.form.currency.title
                    : this.form.currency;
            this.errors.price = '';

            if (v == null || v.length == 0 || v == 0) {
                valid = false;
                this.errors.price = this.$t('forms.placeholder.price');
                return;
            }

            if (v % 1 != 0) {
                this.errors.price = this.$t('errors.numeric.price');
                return;
            }

            if (isNaN(Number(v))) {
                valid = false;
                this.errors.price = this.$t('errors.numeric.price');
                return;
            }

            if (Money.isCurrencyRUB(currency)) {
                let min = v >= 99;
                let max = v <= 100000;

                if (!(min && max)) {
                    valid = false;
                    this.errors.price = this.$t('plan.priceRangeRUB');
                }
            }

            if (Money.isCurrencyUSD(currency) || Money.isCurrencyEUR(currency)) {
                let min = v >= 5;
                let max = v <= 500;

                if (!(min && max)) {
                    valid = false;
                    this.errors.price = Money.isCurrencyUSD(currency)
                        ? this.$t('plan.priceRangeUSD')
                        : this.$t('plan.priceRangeEUR');
                }
            }

            return valid;
        },
        isPlanValid(plan) {
            return this.planPriceWithDiscount(plan) >= Plan.getMinPrice(plan);
        },
        planPriceWithDiscount(plan) {
            if (plan.price && plan.price.isCurrencyRUB()) {
                return Math.round((plan.price.price() / 100) * (100 - this.form.discount));
            } else {
                return ((plan.price.price() / 100) * (100 - this.form.discount)).toFixed(2).replace('.00', '');
            }
        },
        planError(plan) {
            this.isDisabled = true;
            return this.$t('promotions.form.errorPrice').replace(
                '%value%',
                Plan.getMinPrice(plan) + plan.price.currencyTitle()
            );
        },
        validationStock(val) {
            this.isDisabled = !(
                val.selectedPlans.length != 0 &&
                this.errors.title.length == 0 &&
                val.title != null &&
                val.title != '' &&
                this.errors.discount.length == 0 &&
                val.discount != null &&
                val.discount != ''
            );
        },
        validationPromoCode(val) {
            this.isDisabled = !(
                val.selectedPlans.length != 0 &&
                this.errors.title.length == 0 &&
                val.title != null &&
                val.title != '' &&
                this.errors.discount.length == 0 &&
                val.discount != null &&
                val.discount != '' &&
                val.usage.total != '0'
            );
        },
        validationTariff(val) {
            if (
                val.selectedChannels &&
                val.selectedChannels.length != 0 &&
                this.errors.title.length == 0 &&
                val.title != null &&
                val.title != '' &&
                this.errors.price.length == 0 &&
                val.price != null &&
                val.price != '' &&
                val.currency != null &&
                val.currency != '' &&
                val.period != null &&
                val.period != ''
            )
                return (this.isDisabled = false);

            this.isDisabled = true;
        },
        validationComebacker(val) {
            let formHours = val.hours;
            let formMinutes = val.minutes;
            let minutes = formHours * 60 + Number(formMinutes);

            this.isDisabled = !(
                val.selectedPlans.length != 0 &&
                this.errors.title.length == 0 &&
                val.title != null &&
                val.title != '' &&
                this.errors.discount.length == 0 &&
                val.discount != null &&
                val.discount != '' &&
                this.errors.textSubscription.length == 0 &&
                val.textSubscription != null &&
                val.textSubscription != '' &&
                minutes >= 30 &&
                minutes <= 1440
            );
        },

        setDefaultStock(val) {
            _.each(this.errors, (v, title) => (this.errors[title] = ''));

            if (val && val != null && val.id) {
                this.form.title = val.title;
                this.form.isShowPeriod = val.isShowPeriod;
                this.isEditable = val.editable;
                this.form.date = {
                    start: val.startAt ? val.startAt.split('T')[0] : null,
                    end: val.endAt ? val.endAt.split('T')[0] : null,
                };
                this.form.discount = val.discount;
                this.form.selectedPlans = _.map(val.plans, p => p.id);
                this.form.id = val.id;
                return;
            }

            delete this.form.id;
        },

        setDefaultPromoCode(val) {
            _.each(this.errors, (v, title) => (this.errors[title] = ''));

            if (val && val != null && val.id) {
                this.form.title = val.title;
                this.form.isShowPeriod = val.isShowPeriod;
                this.isEditable = val.editable;
                this.form.date = {
                    start: val.startAt ? val.startAt.split('T')[0] : null,
                    end: val.endAt ? val.endAt.split('T')[0] : null,
                };
                this.form.discount = val.discount;
                if (val.capacity) {
                    this.form.usage.total =
                        val.capacity + val.subscriptionsCount != 0 ? val.capacity + val.subscriptionsCount : null;
                } else {
                    this.form.usage.total = null;
                }
                this.form.selectedPlans = _.map(val.plans, p => p.id);
                this.form.id = val.id;
                return;
            }

            delete this.form.id;
        },

        setDefaultData(val) {
            _.each(this.errors, (v, title) => (this.errors[title] = ''));

            if (val && val != null && val.id) {
                this.form.title = val.title;
                this.form.period = val.period.id;
                this.form.freePeriod = val.free.id;
                this.form.price = val.price.amount;
                this.form.currency = val.price.currency;
                this.form.resources = val.getResources();
                this.form.id = val.id;
                this.isDisabledPrice =
                    _.find(this.promotions, p => _.some(p.plans, pl => pl.id == val.id)) ||
                    _.find(this.comebackersList, p => _.some(p.plans, pl => pl.id == val.id))
                        ? true
                        : false;
                this.isDisabledPCode = _.find(this.promocodes, p => _.some(p.plans, pl => pl.id == val.id))
                    ? true
                    : false;
                //this.setResources();
                return;
            }

            this.form.title = null;
            this.form.period = null;
            this.form.trial = null;
            this.form.price = null;
            this.form.currency = null;
            this.form.resources = [];
            this.resources = _.filter([...this.projectChannels, ...this.projectGroups], v => v.isActive());
            //this.setResources();
            delete this.form.id;
        },
        setDefaultComebackerData(val) {
            _.each(this.errors, (v, title) => (this.errors[title] = ''));

            this.form.textSubscription = this.$t('promotions.comebacker.textSubscription');

            if (val && val != null && val.id) {
                this.form.title = val.title;
                this.form.hours = Math.trunc(val.duration / 60 / 60) != 0 ? Math.trunc(val.duration / 60 / 60) : null;
                this.form.minutes =
                    Math.trunc(val.duration / 60) - this.form.hours * 60 != 0
                        ? Math.trunc(val.duration / 60) - this.form.hours * 60
                        : null;
                this.form.discount = val.discount;
                this.form.textSubscription = val.description;
                this.form.selectedPlans = _.map(val.plans, p => p.id);
                this.form.id = val.id;
                return;
            }

            delete this.form.id;
        },
        setResources() {
            this.resources = [];
            this.form.selectedChannels = [];

            _.each(
                _.filter(this.projectChannels, v => v),
                rA => {
                    let r = _.cloneDeep(rA);

                    _.each(this.form.resources, fr => {
                        if (r.id == fr.id) r.checked = true;
                    });

                    this.resources.push(r);
                }
            );

            _.each(
                _.filter(this.projectGroups, v => v),
                rA => {
                    let r = _.cloneDeep(rA);

                    _.each(this.form.resources, fr => {
                        if (r.id == fr.id) r.checked = true;
                    });

                    this.resources.push(r);
                }
            );

            this.form.selectedChannels = _.map(
                _.filter(this.resources, r => r.checked),
                rr => rr.id
            );
        },

        addCode() {
            this.addPromocode(this.prepareCodeDate())
                .then(i => {
                    this.project.addPromocode(i);
                    this.$emit('addPromocode');
                    setTimeout(() => {
                        this.$notify('success', this.$t('success.promocodeCreated'));
                        this.hide();
                    }, 3500);
                })
                .catch(v => {
                    this.$notify('error', '😢 &nbsp;&nbsp;&nbsp;' + v);
                    this.isloading = false;
                });
        },

        editCode() {
            let data = this.prepareCodeDate();
            data.id = this.form.id;

            this.editPromocode(data)
                .then(i => {
                    this.project.editPromocode(i);
                    this.$emit('editPromocode');
                    setTimeout(() => {
                        this.$notify('success', this.$t('success.promocodeEdited'));
                        this.hide();
                    }, 3500);
                })
                .catch(v => {
                    this.$notify('error', '😢 &nbsp;&nbsp;&nbsp;' + v);
                    this.isloading = false;
                });
        },

        addStock() {
            this.addPromotion(this.prepareStockDate())
                .then(async i => {
                    this.$notify('success', this.$t('success.promotionCreated'));
                    this.project.addPromotion(i);
                    this.hide();
                })
                .catch(v => {
                    this.$notify('error', '😢 &nbsp;&nbsp;&nbsp;' + v);
                    this.isloading = false;
                });
        },

        editStock() {
            let data = this.prepareStockDate();
            data.id = this.form.id;

            this.editPromotion(data)
                .then(i => {
                    i.canBeDeleted = this.item.canBeDeleted;
                    this.$notify('success', this.$t('success.promotionEdited'));
                    this.hide();
                    this.project.editPromotion(i);
                })
                .catch(v => {
                    this.$notify('error', '😢 &nbsp;&nbsp;&nbsp;' + v);
                    this.isloading = false;
                });
        },

        editTariff(unarchive = false) {
            let data = this.prepareTariffData();
            data.id = this.form.id;

            this.editPlan(data)
                .then(async i => {
                    await this.saveChannels();
                    await this.saveGroups();

                    if (unarchive == false) this.$notify('success', this.$t('success.planEdited'));

                    i.setChannels(this.getSelectedChannels());
                    i.setGroups(this.getSelectedGroups());
                    i.setCanBeDeleted(this.item.canBeDeleted);

                    this.$store.commit('project/editPlan', i);
                    this.$store.commit('plans/edit', i);

                    this.project.isReady();
                    this.hide();
                })
                .catch(v => {
                    this.$notify('error', '😢 &nbsp;&nbsp;&nbsp;' + v);
                    this.isloading = false;
                });
        },

        addTariff() {
            let data = this.prepareTariffData();

            this.addPlan(data)
                .then(async i => {
                    this.form.id = i.id;
                    this.$notify('success', this.$t('success.planCreated'));
                    this.$store.commit('plans/putChannels', { id: this.form.id, items: this.getSelectedChannels() });
                    this.$store.commit('plans/putGroups', { id: this.form.id, items: this.getSelectedGroups() });

                    this.saveChannels();
                    this.saveGroups();

                    this.$store.commit('project/addPlan', {
                        plan: i,
                        active: this.me.hasFilledAccount() && this.me.hasFilledPaymentAccounts(),
                    });

                    this.hide();
                })
                .catch(v => {
                    this.$notify('error', '😢 &nbsp;&nbsp;&nbsp;' + v);
                    this.isloading = false;
                });
        },

        addComeback() {
            this.addComebacker(this.prepareComebackerDate())
                .then(async i => {
                    this.$store.commit('project/addComebacker', i);
                    this.updatePlans();
                    this.$notify('success', this.$t('success.сambeckerCreated'));
                    this.hide();
                })
                .catch(v => {
                    this.$notify('error', '😢 &nbsp;&nbsp;&nbsp;' + v);
                    this.isloading = false;
                });
        },

        editComeback() {
            let data = this.prepareComebackerDate();
            data.id = this.form.id;

            this.editComebacker(data)
                .then(i => {
                    this.updatePlans();
                    this.$notify('success', this.$t('success.promotionEdited'));
                    this.hide();
                })
                .catch(v => {
                    this.$notify('error', '😢 &nbsp;&nbsp;&nbsp;' + this.$t('errors.comebackerNotEditableError'));
                    this.isloading = false;
                });
        },

        archiveComeback() {
            let data = {};
            data.id = this.form.id;

            this.archiveComebacker(data)
                .then(i => {
                    this.getComebackersList({ project: this.lastOpenedProjectId });
                    this.$notify('success', this.$t('success.promotionEdited'));
                    this.hide();
                })
                .catch(v => {
                    this.$notify('error', '😢 &nbsp;&nbsp;&nbsp;' + v);
                    this.isloading = false;
                });
        },

        prepareStockDate() {
            let out = {
                title: this.form.title,
                isShowPeriod: this.form.isShowPeriod,
                discount: Number(this.form.discount),
                plans: this.form.selectedPlans,
                startAt: this.form.date.start ? moment(this.form.date.start).format('YYYY-MM-DDT00:00:00') : null,
                endAt: this.form.date.end ? moment(this.form.date.end).format('YYYY-MM-DDT23:59:59') : null,
            };

            return out;
        },

        prepareCodeDate() {
            let subscriptionsCount = this.item.subscriptionsCount ? this.item.subscriptionsCount : 0;

            let out = {
                code: this.form.title,
                isShowPeriod: this.form.isShowPeriod,
                discount: Number(this.form.discount),
                plans: this.form.selectedPlans,
                startAt: this.form.date.start ? moment(this.form.date.start).format('YYYY-MM-DDT00:00:00') : null,
                endAt: this.form.date.end ? moment(this.form.date.end).format('YYYY-MM-DDT23:59:59') : null,
                capacity: this.form.usage.total ? Number(this.form.usage.total - subscriptionsCount) : null,
            };

            return out;
        },

        prepareTariffData() {
            let currency = _.isObject(this.form.currency) ? this.form.currency.title : this.form.currency;

            let data = {
                title: this.form.title,
                price: {
                    amount: String(Number(this.form.price) * 100),
                    currency: { code: currency },
                },
                paymentInterval: this.form.period == _.last(PERIODS).id ? null : this.form.period,
                trialInterval: this.form.freePeriod == _.first(PERIODSFREE).id ? null : this.form.freePeriod,
                project: this.project.id,
            };

            return data;
        },

        prepareComebackerDate() {
            let minutes = this.form.minutes ? Number(this.form.minutes) * 60 : null;
            let hours = this.form.hours ? Number(this.form.hours) * 3600 : null;

            let data = {
                title: this.form.title,
                discount: Number(this.form.discount),
                description: this.form.textSubscription,
                plans: this.form.selectedPlans,
                duration: hours + minutes,
                status: { value: 'ACTIVE' },
                project: this.project.id,
            };

            return data;
        },

        async saveChannels() {
            return new Promise((res, rej) => {
                this.putChannels({ id: this.form.id, channels: _.map(this.getSelectedChannels(), r => r.id) })
                    .then(i => (res ? res() : null))
                    .catch(i => (rej ? rej() : null));
            });
        },

        async saveGroups() {
            return new Promise((res, rej) => {
                this.putGroups({ id: this.form.id, groups: _.map(this.getSelectedGroups(), r => r.id) })
                    .then(i => (res ? res() : null))
                    .catch(i => (rej ? rej() : null));
            });
        },

        getSelectedChannels() {
            return _.filter(this.projectChannels, fr => _.some(this.form.selectedChannels, ch => ch == fr.id));
        },

        getSelectedGroups() {
            return _.filter(this.projectGroups, fr => _.some(this.form.selectedChannels, ch => ch == fr.id));
        },

        hide() {
            this.$bvModal.hide(this.modalId);
            this.isloading = false;
        },
        genPromo(len) {
            const chrs = 'ABDEFGHKMNPQRSTWXZ';
            let str = '';

            for (let i = 0; i < len; i++) {
                let pos = Math.floor(Math.random() * chrs.length);
                str += chrs.substring(pos, pos + 1);
            }
            return str.toUpperCase();
        },
        handleClickArchive(planId) {
            this.$bvModal.show(`tariff-archive-${planId}`);
        },
        validDropdown(data) {
            if (data == 'period') {
                if (!this.form.period) {
                    this.errors.period = this.$t('errors.required.period');
                } else {
                    this.errors.period = '';
                }
            } else if (data == 'currency') {
                if (!this.form.currency) {
                    this.errors.currency = this.$t('errors.required.currency');
                } else {
                    this.errors.currency = '';
                }
            }
        },
        updatePlans() {
            this.getCanBeDeleted({ id: this.project.id }).then(i => {
                this.getPlansPlain({ project: this.project.id }).then(items => {
                    let list = _.each(items, p => {
                        _.each(i.plans, pp => {
                            if (p.id == pp.id) {
                                p.canBeDeleted = pp.isCanBeDeleted;
                            }
                        });
                    });
                    this.$store.commit('plans/list', list);
                });
            });
        },
    },
    watch: {
        item: {
            handler(val) {
                if (this.create == PLAN_TABS.stock) return this.setDefaultStock(val);
                if (this.create == PLAN_TABS.tariff) return this.setDefaultData(val);
                if (this.create == PLAN_TABS.promoCode) return this.setDefaultPromoCode(val);
            },
            deep: true,
        },
        'form.discount'() {
            setTimeout(() => {
                this.errorPricePlan();
            }, 30);
        },
        form: {
            handler(val, oldVal) {
                if (this.create == PLAN_TABS.stock) {
                    this.validationStock(val);
                } else if (this.create == PLAN_TABS.promoCode) {
                    this.validationPromoCode(val);
                } else if (this.create == PLAN_TABS.tariff) {
                    this.validationTariff(val);
                } else if (this.create == PLAN_TABS.comebacker) {
                    this.validationComebacker(val);
                }

                this.dateCounter = this.getDateCounter;
            },
            deep: true,
        },
        errors: {
            handler(val, oldVal) {
                if (this.create == PLAN_TABS.stock) {
                    this.validationStock(this.form);
                } else if (this.create == PLAN_TABS.promoCode) {
                    this.validationPromoCode(this.form);
                } else if (this.create == PLAN_TABS.tariff) {
                    this.validationTariff(this.form);
                } else if (this.create == PLAN_TABS.comebacker) {
                    this.validationComebacker(this.form);
                }

                this.dateCounter = this.getDateCounter;
            },
            deep: true,
        },
        'form.selectedChannels': {
            handler(nV, oV) {
                if (nV.length == 0 && oV.length == 0) {
                    this.errorResource = false;
                } else if (nV.length != oV.length && this.form.selectedChannels == 0) {
                    this.errorResource = true;
                } else if (this.form.selectedChannels > 0) {
                    this.errorResource = false;
                }
            },
            deep: true,
        },
        'form.selectedPlans': {
            handler(nV, oV) {
                if (nV.length == 0 && oV.length == 0) {
                    this.errorRate = false;
                } else if (nV.length != oV.length && this.form.selectedPlans == 0) {
                    this.errorRate = true;
                } else if (this.form.selectedPlans > 0) {
                    this.errorRate = false;
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.add-promotion {
    @include respond-below(sm-new) {
        flex-grow: 1;
        margin-top: 5px;
    }

    .blocked {
        .date-picker__date-counter {
            background-color: var(--grey-bg-color);
        }

        .date-picker__input {
            background-color: var(--foreground-color);
            border-color: var(--grayscale-dark-heavy);
        }
    }

    &__gen {
        width: max-content;
    }

    &__tooltip-discount {
        z-index: 1;
        position: absolute;
        top: 13px;
        right: 22px;
        width: 27px;
        height: 27px;
        color: var(--primary-text-color);
        background-color: var(--foreground-color);
        border-radius: 50%;
        cursor: pointer;

        &.blocked {
            background-color: var(--foreground-color);
        }

        @include respond-below(sm) {
            right: 16px;
        }
    }

    &__text-discount {
        position: absolute;
        bottom: 6px;
        left: 70px;
    }

    #display-payment {
        min-width: 5px;
        min-height: 5px;

        & + .custom-control-label {
            padding-top: 2px;
            padding-left: 6px;

            @include respond-below(sm-new) {
                padding-left: 7px;
            }

            &::after,
            &:before {
                width: 20px !important;
                height: 20px !important;
                border-radius: 4px;
            }
        }
    }

    .custom-checkbox:has(#display-payment) {
        min-height: 20px;
        margin-bottom: 25px;

        @include respond-below(sm-new) {
            margin-bottom: 20px;
            margin-top: -3px;
        }
    }

    &__plan {
        background-color: var(--bg-back);
        border-radius: var(--border-radius-rounded);
        transition:
            background-color 0.4s ease,
            box-shadow 0.4s ease;

        &:hover {
            background-color: var(--foreground-color);
            box-shadow: 0px 2px 15px 0px rgba(#000000, 0.08);

            .custom-control-label::before,
            .add-promotion__plan-top::before {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='6' fill='%23ffffff'/%3E%3C/svg%3E%0A");
            }
        }

        &.is-dark {
            &:hover {
                .custom-control-label::before,
                .add-promotion__plan-top::before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='6' fill='%2327282A'/%3E%3C/svg%3E%0A");
                }
            }
        }

        &-archive {
            &--rect {
                fill: var(--brand-bg-archive);
            }

            &--path {
                stroke: var(--grayscale-color-grey);
            }
        }

        &.active-plan {
            background-color: var(--primary-bg-color);

            .add-promotion__plan-archive--rect {
                fill: var(--brand-bg-archive-active);
            }
        }

        &.is-blocked {
            pointer-events: none;
        }

        &-bottom {
            margin-top: 4px;
            margin-right: 1px;
            margin-left: 1px;

            @include respond-below(xs) {
                margin-top: 8px;
                justify-content: flex-start !important;
            }
        }

        &-header {
            margin-right: 7px;
        }

        .custom-control-input {
            display: none;
        }

        .custom-control {
            padding: 0;
        }

        &-top {
            position: relative;

            &::before,
            &::after {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                left: -34px;
                display: block;
                width: 24px;
                height: 24px;
                content: '';
                background: no-repeat 50% / 50% 50%;
            }

            &:before {
                border-radius: 6px;
                border: 1px solid #52a7f9 !important;
                transition:
                    background 0.4s ease,
                    border 0.4s ease;
                background: center / cover no-repeat
                    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='6' fill='%23F7F7F8'/%3E%3C/svg%3E%0A");
            }
        }

        &.is-dark {
            .add-promotion__plan-top {
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='6' fill='%23333333'/%3E%3C/svg%3E%0A");
                }
            }
        }

        .custom-control-input:checked ~ .custom-control-label .add-promotion__plan-top {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='6' fill='%2352A7F9'/%3E%3C/svg%3E%0A");
            }

            &::after {
                background-image: url(/assets/img/icons/done-white.svg);
            }
        }

        .custom-control-label {
            padding: 10px 19px 10px 54px;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            &::before,
            &::after {
                display: none;
                top: 10px !important;
                left: 20px !important;
            }

            &::before {
                transition:
                    background 0.4s ease,
                    border 0.4s ease;
                background: center / cover no-repeat
                    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='6' fill='%23F7F7F8'/%3E%3C/svg%3E%0A");
            }
        }

        &.is-dark {
            .custom-control-label {
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='6' fill='%23333333'/%3E%3C/svg%3E%0A");
                }
            }
        }

        .custom-control-input:checked ~ .custom-control-label {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='6' fill='%2352A7F9'/%3E%3C/svg%3E%0A");
            }
        }

        &.error-plan {
            background-color: var(--red-light-bg-color) !important;

            .custom-control-input:checked ~ .custom-control-label {
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='6' fill='%23FF7C7C'/%3E%3C/svg%3E%0A");
                    border-color: var(--red-text-color) !important;
                }
            }

            .add-promotion__plan-archive--rect {
                fill: var(--brand-bg-archive-error);
            }
        }
    }

    &__price {
        &--old {
            color: var(--grey-text-color);
            text-decoration: line-through;

            @include respond-below(xs) {
                margin-right: 6px;
            }
        }
    }

    &__channel {
        background-color: var(--bg-back);
        border-radius: var(--border-radius-rounded);
        transition:
            background-color 0.4s ease,
            box-shadow 0.4s ease;
        padding: 0;

        &:hover {
            background-color: var(--foreground-color);
            box-shadow: 0px 2px 15px 0px rgba(#000000, 0.08);
        }

        &.active-plan {
            background-color: var(--primary-bg-color);

            .brand-gradient {
                background: #59cb94;
            }
        }

        .custom-control-label {
            padding: 10px 18px 10px 52px;

            &::before,
            &::after {
                top: 50% !important;
                transform: translateY(-50%) !important;
                left: 18px !important;
            }

            &::before {
                background-color: transparent;
            }
        }
    }

    &__avatars {
        width: 41px;
        min-width: 41px;
        height: 41px;
        min-height: 41px;
        font-size: 17px;
        line-height: 14px;
        text-transform: uppercase;
        color: var(--foreground-color);
        border-radius: 50%;

        img {
            display: block;
            border-radius: 50%;
            width: 100%;
            height: 100%;
        }

        &.brand-gradient {
            background: var(--brand-gradient-primary, linear-gradient(227deg, #0071f4 0%, #66b4ff 100%));
        }
    }

    &__modal {
        &-discount {
            padding: 16px 20px 28px;
        }

        &-txt {
            margin-bottom: 36px;
        }
    }

    &__disclaimer-btn {
        height: 38px;
        max-width: 170px;
        margin-top: 10px;
    }

    &__input {
        margin-bottom: 25px;

        @include respond-below(sm-new) {
            margin-bottom: 20px;
        }

        &--w-50 {
            width: calc(50% - 5px);

            @include respond-below(xs) {
                width: 100%;
            }

            &.is-validity:first-of-type {
                @include respond-below(xs) {
                    margin-bottom: 10px;
                }
            }
        }

        &.is-discount {
            div:has(.my-input.is-focused),
            div:has(.my-input.is-error) {
                .add-promotion__tooltip-discount {
                    background-color: var(--bg-back);
                }
            }

            @include respond-below(xs) {
                flex-wrap: wrap;
            }
        }
    }

    &__textarea-text {
        margin-bottom: 25px;

        @include respond-below(sm-new) {
            margin-bottom: 20px;
        }
    }

    &__subscription {
        font-size: 12px;

        @include respond-below(xs) {
            margin-right: 9px;
        }
    }

    &__comeback {
        border: 1px solid var(--grayscale-dark-line);
        font-size: 12px;
        padding: 7px 10px;

        &.is-count {
            color: #ac7dd1 !important;
        }
    }

    &__button {
        margin-top: 15px;
    }

    &__usage {
        margin-left: 18px;

        @include respond-below(xs) {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    &__error {
        margin-bottom: 10px;
    }

    &__warning {
        margin: 0 20px 10px;
        border: 1px solid var(--grayscale-dark-line);
        background-color: var(--brand-bg-warning);
        padding: 9.5px 19px;

        &-icon {
            margin-right: 12px;
        }

        &-txt {
            color: var(--brand-color-warning);
        }

        &-archive {
            margin: 0 0 10px 0;
            border: none;
            padding: 21px;
        }
    }

    &__archive {
        background: var(--primary-light-color);
        border: none;
        width: 50px;

        &-icon {
            stroke: var(--brand-primary);
            transition: stroke 0.4s ease;
        }

        &:hover {
            .add-promotion__archive-icon {
                stroke: var(--primary-dark-text-color);
            }
        }
    }

    &__bot-disconnected {
        padding: 1px 6.5px 2px !important;
        margin-top: 5px;
    }
}
</style>
