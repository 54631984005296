import { render, staticRenderFns } from "./Authors.vue?vue&type=template&id=7ed724c2&scoped=true&lang=pug"
import script from "./Authors.vue?vue&type=script&lang=js"
export * from "./Authors.vue?vue&type=script&lang=js"
import style0 from "./Authors.vue?vue&type=style&index=0&id=7ed724c2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ed724c2",
  null
  
)

export default component.exports